<template>
    <!-- 表单管理 -->
    <div class="formment-box">
        <div class="box">
            <div class="title">
                <div class="t-label">
                    <label @click="$router.push('/productMent/template')">参数模版</label>
                </div>
                <img src="@/assets/images/icon/arrow-rigth-black.png" />
                <div class="t-label current">
                    <label>{{ type == 1 ? "添加模版" : "修改模版" }}</label>
                </div>
            </div>
            <div class="setbox">
                <div class="inputbox">
                    <el-form ref="formRef" :model="form" label-width="150px" :rules="rules">
                        <el-form-item label="模版名称" prop="tempName">
                            <el-input v-model="form.tempName" placeholder="请输入模版标题"></el-input>
                        </el-form-item>
                        <el-form-item label="参数内容" prop="tempContent">
                            <div class="temp-box" v-for="(item, index) in form.tempContent" :key="index">
                                <div style="padding: 20px 12px">
                                    <div style="display: flex">
                                        <span style="width: 60px;user-select: none"> 参数名 </span>
                                        <el-input style="width: 50%" v-model="item.key" placeholder="请输入模版名"></el-input>
                                        <div @click="deleteItem(index)" style="margin-left: auto; line-height: 1">
                                            <img style="width: 32px; height: 32px; cursor: pointer" src="@/assets/images/icon/trash-can-icon.png" />
                                        </div>
                                    </div>

                                    <div style="display: flex; margin-top: 20px">
                                        <span style="width: 60px;user-select: none;"> 参数值 </span>
                                        <el-input style="width: 50%" v-model="item.value" placeholder="请输入模版值"></el-input>
                                    </div>
                                </div>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="btn-box" style="margin-top: 0">
                <el-button icon="el-icon-plus" class="addtempbtn" @click="addtempClick">
                    <span>添加参数内容</span>
                </el-button>
            </div>
            <div class="btn-box">
                <el-button @click="reset">重置</el-button>
                <el-button v-if="type == '1'" type="primary" @click="btnclick">添加</el-button>
                <el-button v-else-if="type == '2'" type="primary" @click="editInfo">编辑</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { sessionGetKey } from "@/utils/sessionStorage.js";
import rules from "@/utils/rules";
import deepCopy from "@/utils/deepCopy";
export default {
    data() {
        return {
            rules: rules,
            platform_id: "", //平台ID
            type: "1", //1为添加 2是编辑
            form: {
                id: "",
                tempName: "",
                tempContent: [
                    {
                        key: "",
                        value: "",
                    },
                ],
            },
            storageForm: {},
        };
    },
    components: {},
    mounted() {
        this.platform_id = sessionGetKey("platformId");
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        }

        if (this.$route.query.id) {
            this.getTemp(this.$route.query.id);
        }
    },
    methods: {
        // 参数内容删除
        deleteItem(i) {
            if (i > 0) {
                this.form.tempContent.splice(i, 1);
            }
        },
        getTemp(id) {
            const that = this;
            that.$request({
                url: that.$api.goodTemplate.getTemp,
                method: "post",
                data: { id },
                params: {
                    platform_id: that.platform_id,
                },
            }).then((res) => {
                that.form.id = id;
                that.form.tempName = res.data[0].name;
                that.form.tempContent = JSON.parse(res.data[0].content);
                this.storageForm = deepCopy(this.form);
            });
        },
        addtempClick() {
            try {
                if (this.form.tempContent.length > 10) {
                    throw new Error("数量最多不超过10");
                }
                this.form.tempContent = this.form.tempContent.concat({
                    key: "",
                    value: "",
                });
            } catch (err) {
                this.$alert(err.message);
            }
        },
        btnclick() {
            const that = this;
            let { tempName, tempContent } = this.form;
            try {
                if (tempContent && tempContent.length) {
                    let s = tempContent.some((item) => {
                        return item.key == "" || item.value == "";
                    });
                    if (s) {
                        throw new Error("参数名或参数值不能为空");
                    }
                }
            } catch (err) {
                that.$alert(err.message);
                return;
            }

            that.$refs.formRef.validate((valid) => {
                if (valid) {
                    that.$request({
                        url: that.$api.goodTemplate.addTemp,
                        method: "post",
                        data: {
                            name: tempName,
                            content: JSON.stringify(tempContent),
                        },
                        params: {
                            platform_id: that.platform_id,
                        },
                    }).then((res) => {
                        that.$router.push("/productMent/template");
                    });
                }
            });
        },

        editInfo() {
            const that = this;
            let { id, tempName, tempContent } = this.form;
            try {
                if (tempContent && tempContent.length) {
                    let s = tempContent.some((item) => {
                        return item.key == "" || item.value == "";
                    });
                    if (s) {
                        throw new Error("参数名或参数值不能为空");
                    }
                }
            } catch (err) {
                that.$alert(err.message);
                return;
            }

            that.$refs.formRef.validate((valid) => {
                if (valid) {
                    that.$request({
                        url: that.$api.goodTemplate.editTemp,
                        method: "post",
                        data: {
                            id: id,
                            name: tempName,
                            content: JSON.stringify(tempContent),
                        },
                        params: {
                            platform_id: that.platform_id,
                        },
                    }).then((res) => {
                        that.$router.push("/productMent/template");
                    });
                }
            });
        },
        // 重置
        reset() {
            if (this.type == 1) {
                this.form = {
                    tempName: "",
                    tempContent: [
                        {
                            key: "",
                            value: "",
                        },
                    ],
                };
            } else if (this.type == 2) {
                this.form = deepCopy(this.storageForm);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.addtempbtn {
    background: #4458fe;
    color: #fff;
}
.btn-box {
    //   padding: 33px;
    background: #fff;
    margin-top: 100px;
    margin-left: 70px;
}
.editor {
    margin: 20px;
    /deep/.ql-editor {
        min-height: 200px !important;
    }
}
.formment-box {
    // height: 100%;
    .temp-box {
        /deep/.el-input__inner {
            width: 380px !important;
        }
        width: 512px;
        border: 1px solid #999;
        margin-top: 10px;
    }
    padding: 66px;
    //   min-height: 600px;

    .setbox {
        margin-top: 20px;
        color: #333333;

        .title {
            background: #edefff;
            height: 35px;
            line-height: 35px;
            padding-left: 15px;
        }
        /deep/.el-input__inner {
            width: 512px;
        }
        /deep/.input-id {
            margin-left: 20px;
            width: 100px;
        }
        /deep/.input-id .el-input__inner {
            width: 100px;
        }
        /deep/.el-form-item__content {
            // display: flex;
            // align-items: center;
        }
        .inputbox {
            //   margin-left: 15%;

            margin-top: 24px;
        }
    }

    .box {
        // overflow: hidden;
        // position: relative;
        border-radius: 16px;
        width: 100%;
        height: 100%;
        background: #fff;
        padding: 33px;
        .top-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            /deep/.el-breadcrumb__item {
                padding-bottom: 10px;

                font-size: 20px !important;
                //
            }
            /deep/.active {
                color: #4458fe !important;
                border-bottom: #4458fe 2px solid;
                .el-breadcrumb__inner {
                    color: #4458fe !important;
                }
            }
        }
    }
}

.title {
    .t-label {
        display: inline-block;
        height: 35px;

        label {
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            font-size: 20px;
            font-weight: 600;
        }
    }

    img {
        vertical-align: middle;
        width: 24px;
        height: 24px;
    }

    .current {
        border-bottom: 2px solid #4458fe;
        label {
            color: #4458fe;
        }
    }
}
</style>
